import React from 'react';
import SectionWrapper from 'Components/SectionWrapper/SectionWrapper.js';
import './Kontakt.css';
import { motion } from "framer-motion";

const variants = {
  parent: {
    enter: {
      transition: {
        staggerChildren: 0.1,
      }
    }
  },
  title: {
    enter: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 }
  },
  children: {
    enter: {
      y: 0, opacity: 1, transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      }
    },
    exit: { y: 50, opacity: 0 }
  }
};

const Kontakt = () =>
  <div>
    <SectionWrapper title="Kontakt">
      <motion.div variants={variants.parent} className="kontakt">
        <motion.p variants={variants.title}>E-Mail: <a href="mailto:info@gefaesspraxis-bevensen.de">info@gefaesspraxis-bevensen.de</a></motion.p>
        <div>
          <p>Buchen Sie gerne <b>online einen Termin</b> über eine der folgenden Optionen.</p>
          <button style={{ borderRadius: "10px", cursor: "pointer", margin: "10px 10px", border: "2px solid var(--color-dark-blue)", color: "var(--color-dark-blue)", backgroundColor: "var(--color-light-blue)", padding: "15px 20px", fontWeight: "bold", fontSize: "1em" }} type="button" class="terminbuchung-trigger" data-configid="015d35e5-793d-4366-a333-e863c2f7aa04">Terminart Karotis buchen</button>
          <button style={{ borderRadius: "10px", cursor: "pointer", margin: "10px 10px", border: "2px solid var(--color-dark-blue)", color: "var(--color-dark-blue)", backgroundColor: "var(--color-light-blue)", padding: "15px 20px", fontWeight: "bold", fontSize: "1em" }} type="button" class="terminbuchung-trigger" data-configid="912c40ab-7a69-4a93-bce6-7a03eaa696fd">Terminart Sichtung buchen</button>
        </div>
        <motion.h2 variants={variants.title}>So <span>erreichen sie uns.</span></motion.h2>

        <motion.div variants={variants.children} className="location_container">
          <motion.div variants={variants.children} className="location_text_container">
            <h3>Praxis Bad Bevensen</h3>
            <p>Römstedter Straße 25 | 29549 Bad Bevensen<br />(im Herz- und Gefäß-Zentrum)<br /><br /><span>Telefon:</span> 0 58 21 - 96 77 93<br /><span>Fax:</span> 0 58 21 - 96 77 92<br /><br /><span>Öffnungszeiten:</span><br />Montag - Donnerstag: 8:00 Uhr - 17:00 Uhr<br />Freitag: 8.00 Uhr - 14.00 Uhr</p>
          </motion.div>
          <motion.div className="location_image bevensen" variants={variants.children} onClick={() => window.open("https://www.google.com/maps?ll=53.085583,10.603598&z=17&t=m&hl=en&gl=US&mapclient=embed&q=R%C3%B6mstedter+Str.+25+29549+Bad+Bevensen", "_blank")}>
            <p><a href="https://www.google.com/maps?ll=53.085583,10.603598&z=17&t=m&hl=en&gl=US&mapclient=embed&q=R%C3%B6mstedter+Str.+25+29549+Bad+Bevensen">Zu Google Maps</a></p>
          </motion.div>
        </motion.div>

      </motion.div>
    </SectionWrapper>
  </div>
export default Kontakt;
