import React from 'react';
import SectionWrapper from 'Components/SectionWrapper/SectionWrapper.js';

const Leistungen = () =>
      <div>
        <SectionWrapper title="Leistungen" subtitle="Hier finden Sie eine kurze Auflistung unsere Leistungen.">
        <div>
            <ul className="list">
            <li>Farbduplexsonographie</li>
            <li>Systolischer Knöcheldruck</li>
            <li>Oszillographie</li>
            <li>Akrale Plethysmographie</li>
            <li>Vitalkapillarmikroskopie</li>
            <li>Lichtreflexionsrheographie</li>
            <li>Venenverschlussplethysmographie</li>
            <li>Laufbandtest</li>
            <li>Intermittierende apparative Kompressionstherapie</li>
            <li>Infusionstherapie</li>
            <li>Behandlung chronischer Wunden</li>
            <li>Behandlung des diabetischen Fußes</li>
            </ul>

            <h3>Ambulante Operationen</h3>
            <ul className="list">
            <li>Dialysezugänge: Erstanlagen und Korrekturen von arterio-venösen Fisteln (AV-Shunts)</li>
            <li>Bedarfsgerechte Krampfaderbehandlung unter medizinischen und kosmetischen Aspekten:  in Allgemein- oder Lokalanästhesie, endovenöse Radiofrequenzablation (RFA) und Lasertherapie (EVLT) , Verödungstherapie, Schaumsklerosierung</li>
            <li>Operative Wundtherapie: Debridements, Vacuumtherapie, Spalthautplastik etc.</li>
            <li>Chirurgie  der Körperoberfläche: Entfernung von Haut- und Unterhauttumoren, Abszessentlastung etc.</li>
            <li>Minoramputationen</li>
            </ul>

        </div>
        </SectionWrapper>
    </div>
export default Leistungen;
